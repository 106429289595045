@tailwind base;
@tailwind components;
@tailwind utilities;
@import "rsuite/dist/rsuite.css";

.rs-picker-toggle {
  z-index: 1000;
}

.barsMenu {
  float: left;
  display: none;
  background: none;
}

#map {
  background-color: #f2f2f2;
}

.ant-menu-horizontal {
  border: none;
}

.ant-menu-submenu-popup ul {
  margin: 0;
  padding: 0;
  width: 100%;

}

.ant-menu-sub.ant-menu-item {
  width: 50%;
}

.ant-menu-submenu-popup ul li {
  display: inline-block;
  vertical-align: top;
  /* width:35%; */

}

.ant-menu-submenu-popup {
  width: 40%;

}

.ant-menu-vertical>li.ant-menu-item {
  width: 50%;
}

.ant-menu-vertical>li.ant-menu-item:hover {
  color: #198AC8;
}

.smile {
  position: absolute;
  height: 64%;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  opacity: 0.1;
}

.edgeRight {
  position: absolute;
  height: 123%;
  bottom: 0;
  RIGHT: 0;
  opacity: 0.1;
  transform: translateX(0%) translateY(18px);
}

.edgeLeft {

  opacity: 0.1;
  position: absolute;
  scale: -1;
  height: 148%;
  transform: translateY(22%) translateX(55px);

}

.bottomFooter {
  width: 95%;
  margin-left: 3%;
  margin-top: 10px;
  display: block;
  bottom: 0;
}
.spanpcdelai{
  position: relative;
  left: 50%;
  transform: translateX(-25%);

}

.topFooter {
  width: 100%;
  color: white;
  transform: translate(13%);
  display: block;
}

.edgeRightH {
  position: absolute;
  height: 116%;
  /* bottom: 0; */
  RIGHT: 0;
  opacity: 0.1;
  transform: translateX(33%) translateY(-161px);
}

.edgeRightMyH {
  position: absolute;
  height: 116%;
  /* bottom: 0; */
  RIGHT: 0;
  opacity: 0.1;
  transform: translateX(13%) translateY(-37%);


}

.edgeLeftMyH {
  opacity: 0.1;
  position: absolute;
  scale: -1;
  height: 197%;
  transform: translateY(52%) translateX(3%);

}

.logo:hover {
  cursor: pointer;
}

.DashedDiv {
  height: 103px;
  width: -webkit-fill-available;
  background: transparent;
  border: 1px dashed #0369A1;
  transform: translateY(6%);
  z-index: 1;
  position: absolute;
}

.Myheadtext {
  z-index: 10;
  position: absolute;
  TOP: 25%;
  width: 100%;
}

.smileMyH {
  position: absolute;
  height: 100%;
  bottom: 0;
  transform: translateX(-48%) translateY(-21%) rotate(-3deg);
  /* transform: scale(1.5); */
  left: 50%;
  opacity: 0.1;
}

.descBody {
  display: flex;
  margin: 13px 32px 0 32px;
}

.DescWrapper {
  padding: 10px;
}

.social {
  padding: 10px;
  color: white !important;
}

.SocialIcons {
  position: absolute;
  bottom: 56%;
  left: 57%;
}

.landingPageImg {
  z-index: 1000;
  float: right;
  POSITION: relative;
  width: 50%;
  height: -webkit-fill-available;
}

.btnMore {
  border-radius: 68px;
  background-color: rgb(99, 196, 53);
  color: white;
  /* width: 148px; */
  padding: 0 30px 0 30px;
  border-color: #63C435;
}

.btnMore:hover {
  border-color: white;
  color: white;
}
.leaflet-popup-content-wrapper {
  background-color: white!important;
  opacity: 0.8!important;
  width: 400px !important;
  z-index: 9000000000;
}
.leaflet-popup-content{
  width: 345px !important;
}
.leaflet-popup{
  width:auto !important;

}
.btnDiscover {
  border-radius: 68px;
  background-color: #2768A3;
  color: white;
  /* width: 148px; */
  padding: 0 30px 0 30px;
  border-color: #2768A3;
}

.btnDiscover:hover {
  border-color: white;
  color: white;
}

.btnDemande {
  border-radius: 68px;
  color: white;
  padding: 0 30px 0 30px;
  border-color: #3C803C;
  background-color: #3C803C;
}

.btnDemande:active {
  border-color: #3C803C;
  background-color: #3C803C;
  color: white;

}

.AutList {
  color: #0369A1;
  font-weight: 600;
  font-size: 12px;
}

.ListAutCards {
  background-color: #F9FAFB;
  height: 271px;
}

.AutListurba {
  margin-top: 15px;
}

.btnDemande:hover {
  border-color: #2DAA2D;
  background-color: #2DAA2D;
  color: white;

}

.btnDemande:focus {
  border-color: #2DAA2D;
  background-color: #2DAA2D;
  color: white;

}

.ImgContent {
  z-index: 2;
  position: absolute;
  TOP: 11%;
  left: 11%;
}

.logoListLong {
  margin-right: 10px;
  float: left;
  height: 37px;
}

.textLogoLong {
  width: 100%;
  height: auto;
  padding: 1%
}

.logoList {
  margin-right: 10px;
}

a {
  color: white;
}

.Wrapper {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 66%;
  margin: 0 10px 10px 10px;
  transform: rotate(6deg) translateY(-105px);
}

.TextDesc {
  text-align: center;
  width: 62%;
  margin-left: 20%;
  font-size: 20px;
}

.site-card-wrapper,
.borderless-card-wrapper {
  margin-left: 13%;
  padding: 30px;
  margin-right: 10%;

}

.flipCard {
  border-color: #0369A1;
  width: 200px;
  cursor: pointer;
  height: 206px;

}

.flipCard p {
  color: #1C5182;
  font-size: 20px;
  font-weight: normal;
}

.flipCard img {
  WIDTH: 50%;
}

.ant-card-meta-title {
  white-space: pre-line;
}

.body {
  background-color: #F7F8FA;
}

.bodyContainer {
  align-items: center;
  justify-content: center;

}

.borderlessCard {
  background-color: #F0F2F5;
}

.logo:hover {
  border-color: #0369A1;
}

@media (max-width: 783px) {
  .custom-dropdown {
    z-index: 100000;
    box-shadow: 0 0 1px 0px #ccc;
    position: absolute;
    left: 0;
    top: 38px;
    width: 230px;
    /* Adjust the width as needed */
    /* padding: 8px; */
    font-size: 14px;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    background-color: #ffffff;
    color: #333;
    appearance: none;
    -webkit-appearance: none;
    /* background-image: linear-gradient(45deg, transparent 50%, #333 50%),
      linear-gradient(135deg, #333 50%, transparent 50%); */
    /* background-position: calc(100% - 10px) center, calc(100% - 6px) center;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat; */
    cursor: pointer;
  }
  .barsMenu {
    display: inline-block;
  }

  .rightMenu {
    display: none;
  }

  .logo {
    display: none;
  }

  .smile {
    position: absolute;
    height: 53%;
    bottom: 0;
    transform: translateX(-50%)translateY(-65%);
    left: 50%;
    opacity: 0.1;
  }

}

.rfcparti1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 80px;
  width: 100%;
  height: 100%;

}

.monthscontainer {
  top: 17px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  width: 300px;
}

.monthstat {}

.respodiv {
  width: 60%;
}

.selectprefcom {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  width: 100;
}

.pcdounat {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.indicateur {
  display: flex;
  flex-direction: row;
  /*justify-content: center;*/
  flex-wrap: wrap;
  padding-left: 100px;
  gap: 13px 20px;
  width: 90%;
  margin-bottom: 4px;

}

.indica {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.mapp {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;

}
.filter-container1{
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  width: 75%;
  height: 12%;
  left: 50%;
  transform: translateX( -50% );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
   padding: 10px 15px; 
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: content-box;
  gap: 5px;
}
.filter-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
}
.filterupContainer{
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  top: -2px;
  width: 24%;
}
.filterupContainer :hover{
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.buttonWithIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  cursor: pointer;
}

.homeresponsive {
  margin-left: 120px;
}

.donneesOvert {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Vertically center items within columns */
  width: 270px;
  justify-content: center;
  height: 160px;
}

.Month {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  gap: 10px;

}

@media (max-width: 768px) {

  

  .filter-container1{
    width: 75%;
    /* height: 25%; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 10px 15px;  */
    background-color: rgba(0, 0, 0, 0.3);
  }
  .filter-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 15px;
    flex-wrap: wrap;
    gap: 8px;
  }
  .filterupContainer{
    position: static;
    padding-left: 10px;
    cursor: pointer;
    width: 70%;
  }






  .homeresponsive {
    margin-left: 18%;
  }

  .ant-row {
    margin-left: 20;
  }

  .rfcparti1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    height: 100%;


  }

  .mapp {
    width: 100%;
    height: 70%;
    z-index: 0;
    position: relative;

  }

  .rfcparti1 {
    width: 100%;

  }

  .monthscontainer {
    flex-direction: row;

  }

  .respodiv {
    width: 390px;
  }

  .selectprefcom {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2px;
    width: 100;
  }

  .indicateur {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 100px;
    gap: 10px 20px;
    width: 90%;
    margin-left: -30px;
    margin-bottom: 10px;
    height: 100%;

  }
}


.inputsearch-map{
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  width: 85%;
  background-color: white;
  color: #333;
  background-repeat: no-repeat;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  /* cursor: zoom-in; */
}
.btn-searchbar{
  width: 40px;
  height: 100%;
  background-color: #294774;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.filter-down{
  margin-top: 2px;
  margin-left: 4px;
}

.permis-select-icon{

  width: 40px;
  height: 37.5px;
  background-color: #294774;
  
  display: flex;
  top: 0px;
  position: absolute;
  right: -1px;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.dropdown-menu{
  width: 240px;
  height: 37px;
  position: relative;
  text-align: left;
  padding-left: 10px;
  border-radius: 4px;
}

.custom-dropdown>div{
  padding: 3px 5px;
}
.custom-dropdown>div:hover{
  color: #fff;
  background: #294774;
}
.custom-dropdown {
  z-index: 100000;
  box-shadow: 0 0 1px 0px #ccc;
  position: absolute;
  left: 0;
  top: 38px;
  width: 239px;
  /* Adjust the width as needed */
  /* padding: 8px; */
  font-size: 14px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  /* background-image: linear-gradient(45deg, transparent 50%, #333 50%),
    linear-gradient(135deg, #333 50%, transparent 50%); */
  /* background-position: calc(100% - 10px) center, calc(100% - 6px) center;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat; */
  cursor: pointer;
}


/* Hover effect */
.custom-dropdown:hover {
  border-color: #aaa;
}

/* Focus effect */
.custom-dropdown:focus {
  border-color: #007bff;
  outline: none;
}


.react-dates .CalendarMonthGrid {
  z-index: 1001;
  /* Set a higher z-index value */
}

.calendarElement {
  height: 400px;
  /* Adjust the height as needed */
}

.calendar-button {
  position: absolute;
  margin-top: 10px;
  bottom: 2px;
  /* Adjust the position as needed */
  right: 10px;
  /* Adjust the position as needed */
}

/*Wraperclass for the divicon*/
.map-label {
  position: absolute;
  bottom: 0;
  left: -50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: rgb(82, 66, 155);
}

/*Wrap the content of the divicon (text) in this class*/
.map-label-content {
  order: 1;
  position: relative;
  left: -50%;
  background-color: rgb(231, 228, 255);
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(81, 112, 23);
  padding: 3px;
  white-space: nowrap;
}

.map-label-yellow {
  position: absolute;
  bottom: 0;
  left: -50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: rgb(173, 233, 34);
}

.map-label-content-yellow {
  order: 1;
  position: relative;
  left: -50%;
  background-color: rgb(244, 253, 201);
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  color: rgb(150, 150, 58);

  border-color: rgb(178, 223, 14);
  padding: 3px;
  white-space: nowrap;
}

/*Add this arrow*/
.map-label-arrow {
  order: 2;
  width: 0px;
  height: 0px;
  left: 50%;
  border-style: solid;
  border-color: rgb(81, 112, 23) transparent transparent transparent;
  border-width: 10px 6px 0 6px;
  /*[first number is height, second/fourth are rigth/left width]*/
  margin-left: -6px;
}

.map-label.blueborder>.map-label-content {
  border-color: rgb(121, 58, 238);
}

.map-label.blueborder>.map-label-arrow {
  border-top-color: rgb(121, 58, 238);
}

.btn_popup {
  background-color: #294774;
  color: #fff;
  border: none;
  padding: 10px ;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
 
}

.btn_popup:hover {
  background-color: #0056b3;
  /* Change color on hover */
}

.btn_popup:active {
  background-color: #003e7a;
  /* Change color when clicked */
}

/* Initially hide the second card */
.flipCard.gutter-row:nth-child(2) {
  display: none;
}

/* When the first card is hovered, show the second card */
.flipCard.gutter-row:nth-child(1):hover+.flipCard.gutter-row:nth-child(2) {
  display: block;
}

/* check box colors */
.custom-checkbox {
  background-color: #C2CFF7;
  /* Replace with your desired color */
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

#pc1:checked {
  accent-color: #C9E5F7;
}

#pc2:checked {
  accent-color: #C2CFF7;
}

#pc3:checked {
  accent-color: #50C598;
}

#pc4:checked {
  accent-color: #017310;
}

.hiddenElementCard {
  display: none;
}

.flipCard:hover .hiddenElementCard {
  display: block;
  position: absolute;
  background-color: #0269A1;
  top: 0px;
  left: 0px;
  height: 100%;
  text-align: center;
  padding: 24px;
}


/* end check box colors */

/*Dossiers popup*/
.label-data-item{
  color:#0E4368;
  font-size: 13px;
  max-width: auto
  ;
}
.data-item{
display: flex;
flex-direction: row;
gap: 8px;
max-width:400px

}
.popup{
  max-width: auto;
  border-radius: 8PX;
}
/* .prefixinfo{
width: 150px;
} */
.popup-content{
  display: flex;
  flex-direction: column;
  padding: 8px;

}
.prefixinfo {
  WIDTH: 38%;
}
.valeur {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1D5A8D;
}
.valeur:hover {
  white-space: normal;
  width: 60%;
}
/* cluster */
/* Styles for the cluster icon */
